import styled from 'styled-components'
import { spacing, color, typography, Media } from '@renderbus/common/theme'
import { Flex, Button } from '@renderbus/common/components'

const ActivityContainerArrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 120px;
  height: 2px;
  background-color: ${color.primary};
  ${Media.lessThan(Media.small)} {
    width: 80px;
  }
`
export const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  color: white;
`
export const DoubleElTitle = styled.img`
  display: block;
  margin: auto;
  padding-top: 50px;
  max-width: 100%;
  ${Media.lessThan(Media.small)} {
    padding-top: 200px;
  }
`
export const DoubleElTime = styled.img`
  display: block;
  margin: auto;
  padding-top: ${spacing.small};
  max-width: 100%;
`
export const BannerButton = styled(Button)`
  margin-top: ${spacing.large};
  font-size: ${typography.h3};
  font-weight: normal;
`

export const ActivityContainer = styled.div`
  margin-bottom: 120px;
`
export const ActivityTitle = styled.div`
  position: relative;
  margin: 90px auto;
  width: fit-content;
  padding: 0 2em;
  color: ${color.primary};
  font-size: 2.25rem;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h2};
    padding: 0 1em;
  }
`
export const ActivityContainerArrowLeft = styled(ActivityContainerArrow)`
  right: 100%;
  ::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 12px;
    height: 12px;
    background-color: ${color.primary};
    right: 0;
  }
`
export const ActivityContainerArrowRight = styled(ActivityContainerArrow)`
  left: 100%;
  ::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 12px;
    height: 12px;
    background-color: ${color.primary};
    left: 0;
  }
`

export const JoinContainer = styled(Flex)`
  justify-content: space-around;
  flex-wrap: wrap;
`
export const JoinContent = styled(Flex)`
  flex-shrink: 0;
  flex-direction: column;
  color: white;
  img {
    width: 120px;
    padding: ${spacing.base} 0 ${spacing.large};
    margin: 0 ${spacing.small};
  }
`
export const CouponFlex = styled(Flex)`
  flex-wrap: wrap;
`
export const CouponImageContainer = styled.img`
  flex-shrink: 0;
  max-width: 100%;
`
export const CouponRulesContainer = styled.ol`
  width: 600px;
  margin: 0;
  padding-left: 50px;
  flex-grow: 1;
  color: white;
  ${Media.lessThan(Media.small)} {
    padding-left: ${spacing.base};
  }
`
